import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SetNavbarState } from 'src/app/app.actions';
import { AppState } from 'src/app/app.state';
import { Project } from 'src/app/modules/site/models/project.model';

@Component({
	selector: 'ssn-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
	@HostBinding('attr.class') private layoutCls = 'ssn-navigation';
	@HostBinding('class.ssn-navigation--open') private openCls = false;

	@Select(AppState.activeProject)
	public activeProject$: Observable<string>;
	@Select(AppState.navbarState)
	public navbarOpen$: Observable<boolean>;

	@Input() public projects: Project[];
	constructor(private store: Store, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.navbarOpen$.subscribe((open) => {
			this.openCls = open;
		});
	}

	public openNavbar(): void {
		this.store.dispatch(new SetNavbarState(true));
	}
}
