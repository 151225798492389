import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { SetActiveCountry, SetActiveProject, SetNavbarState } from './app.actions';

export class AppStateModel {
	navbarOpen: boolean;
	activeProject: string;
	activeCountry: string;
}

@State<AppStateModel>({
	name: 'app',
	defaults: {
		navbarOpen: false,
		activeProject: null,
		activeCountry: null
	}
})
export class AppState implements NgxsOnInit {
	@Selector()
	static activeProject(state: AppStateModel): string {
		return state.activeProject;
	}
	@Selector()
	static activeCountry(state: AppStateModel): string {
		return state.activeCountry;
	}
	@Selector()
	static navbarState(state: AppStateModel): boolean {
		return state.navbarOpen;
	}

	constructor() {}

	public ngxsOnInit(ctx: StateContext<AppStateModel>) {}

	@Action(SetActiveProject)
	setActiveProject({ patchState }: StateContext<AppStateModel>, action: SetActiveProject) {
		patchState({
			activeProject: action.payload
		});
	}
	@Action(SetActiveCountry)
	setActiveCountry({ patchState }: StateContext<AppStateModel>, action: SetActiveCountry) {
		patchState({
			activeCountry: action.payload
		});
	}
	@Action(SetNavbarState)
	setNavbarState({ patchState }: StateContext<AppStateModel>, action: SetNavbarState) {
		patchState({
			navbarOpen: action.payload
		});
	}
}
