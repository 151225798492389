import { Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'ssn-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
	@HostBinding('attr.class') private layoutCls = 'ssn-map';
	public activeProject: string;

	public get getProjectContent(): SafeHtml {
		switch (this.activeProject) {
			case 'hycristal':
				return this.santizer.bypassSecurityTrustHtml(
					'HyCRISTAL aimed to develop new understanding of East African climate change and variability and works with regional decision-makers to support effective long-term (5 to 40 year) decision-making in the face of a changing climate. In collaboration with a range of stakeholders, and formally supported by the East African Community, HyCRISTAL is co-developing climate change adaptation options that meet the region’s societal needs in both urban and rural areas.'
				);
			case 'amma-2050':
				return this.santizer.bypassSecurityTrustHtml(
					'AMMA-2050 aimed to increase understanding of the regional climate of West Africa and how it will change, applying this knowledge to practical development questions. AMMA-2050 has improved understanding of how the West African monsoon will be affected by climate change in the coming decades (focused on the period up to 2050) and helped West African societies prepare and adapt. AMMA-2050 conducted pilot studies focused on urban flooding in Ouagadougou (Burkina Faso) and climate-resilient agriculture in Senegal, both issues of significant societal concern.'
				);
			case 'umfula':
				return this.santizer.bypassSecurityTrustHtml(
					'UMFULA aimed to improve climate information for medium-term (5-40 year) decision-making in the water-energy-food nexus in central and southern Africa, with a particular focus on Tanzania and Malawi. The team generated new insights and more reliable information about climate processes and extreme weather events and their impact on water, energy and agriculture in the region.'
				);
			case 'fractal':
				return this.santizer.bypassSecurityTrustHtml(
					'FRACTAL aimed to advance understanding of southern Africa’s climate, notably processes, as well as regional and local trends, and to work with decision-makers to integrate scientific knowledge into climate-sensitive decisions at the city-regional scale (particularly decisions relating to water, energy and food with a lifetime of 5 to 40 years).'
				);
			default:
				return '';
		}
	}

	constructor(private santizer: DomSanitizer) {}

	ngOnInit(): void {}

	public getButtonPos(project: string): any {
		const countries = document.getElementsByClassName(project);
		const bounds: any = [];

		for (let i = 0; i <= countries.length - 1; i++) {
			bounds.push(countries[i].getBoundingClientRect());
		}

		if (project === 'project--umfula') {
			bounds.push(document.getElementsByClassName('ssn-map__country--malawi')[0].getBoundingClientRect());
		}

		const navWidth = document.querySelector('.ssn-navigation')?.clientWidth;
		const panelWidth = document.querySelector('.ssn-home-template__content')?.clientWidth;
		const scrollTop = document.querySelector('.mat-drawer-content')?.scrollTop;

		const xMin = Math.min(...bounds.map((b) => b.left));
		const xMax = Math.max(...bounds.map((b) => b.right));
		const yMin = Math.min(...bounds.map((b) => b.top));
		const yMax = Math.max(...bounds.map((b) => b.bottom));

		return {
			top: yMin + (yMax - yMin) * 0.5 + scrollTop + 'px',
			left: xMin + (xMax - xMin) * 0.5 - (navWidth + panelWidth) + 'px',
			transform: 'translate(-50%, -50%)'
		};
	}

	public activateProject(project: string): void {
		this.activeProject = project;
	}
	public deactivateProject(): void {
		this.activeProject = '';
	}
}
