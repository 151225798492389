import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatDrawerContent } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { SetNavbarState } from 'src/app/app.actions';
import { AppState } from 'src/app/app.state';
import { Project } from 'src/app/modules/site/models/project.model';
import { ProjectService } from 'src/app/modules/site/services/project.service';
import { LayoutService } from '../../services/layout.service';

@Component({
	selector: 'ssn-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('drawer') private drawer: MatDrawer;
	@ViewChild('drawerContent') private drawerContent: MatDrawerContent;
	private _scrollSub: Subscription;
	@Select(AppState.navbarState)
	public navbarState$: Observable<boolean>;

	public projects: Project[];
	constructor(
		private store: Store,
		private service: ProjectService,
		private layoutService: LayoutService,
		private router: Router
	) {}

	public ngOnInit(): void {
		this.navbarState$.subscribe((isActive) => {
			if (this.drawer) {
				if (isActive) {
					this.drawer.open();
				} else {
					this.drawer.close();
				}
			}
		});

		this.service.getProjects().subscribe((projects) => {
			this.projects = projects;
		});
	}

	public ngAfterViewInit(): void {
		this.drawer.openedChange.subscribe((state) => {
			this.store.dispatch(new SetNavbarState(state));
		});
		this._scrollSub = this.drawerContent.elementScrolled().subscribe((e) => this.layoutService.scrolling$.next(e));

		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			this.drawerContent.getElementRef().nativeElement.scrollTo(0, 0);
		});
	}

	public ngOnDestroy(): void {
		this._scrollSub.unsubscribe();
		this.layoutService.scrolling$ = null;
	}
}
