import { AfterViewInit, ApplicationRef, HostListener, OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import { SetActiveCountry, SetActiveProject } from 'src/app/app.actions';
import { AppState } from 'src/app/app.state';
import { LayoutService } from 'src/app/modules/layout/services/layout.service';
import { Country } from '../../models/country.model';
import { Project } from '../../models/project.model';
import { ContentService } from '../../services/content.service';
import { ProjectService } from '../../services/project.service';

@Component({
	selector: 'app-country',
	templateUrl: './country.component.html',
	styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit, AfterViewInit, OnDestroy {
	@Select(AppState.activeProject)
	private activeProject$: Observable<string>;
	private scrollingSub: Subscription;
	public country: Country;
	public project: Project;
	public activeSection: string = 'scenarios';

	public get loaded(): boolean {
		return !!this.country && !!this.project;
	}

	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private projectService: ProjectService,
		private contentService: ContentService,
		private layoutService: LayoutService,
		private appRef: ApplicationRef
	) {
		route.params.subscribe((params) => {
			this.activeProject$.subscribe((projectId) => {
				if (!projectId) return;
				this.store.dispatch(new SetActiveCountry(params.country));
				this.loadCountry(projectId, params.country);
			});
		});
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.scrollingSub = this.layoutService.scrolling$.subscribe((evt) => {
			if (!evt) return;
			const scrollable = evt.target as any;
			const scenariosSection = document.getElementById('scenarios')?.getBoundingClientRect();
			if (!scenariosSection) return;

			if (scrollable.scrollTop >= 0 && scrollable.scrollTop + 112 < scenariosSection.bottom + scrollable.scrollTop) {
				if (this.activeSection !== 'scenarios') {
					this.activeSection = 'scenarios';
					this.appRef.tick();
				}
			}

			if (
				scrollable.scrollTop + 112 > scenariosSection.top + scenariosSection.height + scrollable.scrollTop &&
				scrollable.scrollTop <= scrollable.scrollHeight
			) {
				if (this.activeSection !== 'statistics') {
					this.activeSection = 'statistics';
					this.appRef.tick();
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.scrollingSub.unsubscribe();
	}

	private loadCountry(projectId: string, country: string): void {
		this.projectService.getProject(projectId).subscribe((project) => {
			this.project = project;
			this.country = project.countries.find((c) => c.url === country);
			this.contentService.countryLoaded();
		});
	}

	public getSafeHtml(text: string): SafeHtml {
		return this.contentService.sanitizeHTML(text);
	}

	public scrollToSection(section: string): void {
		const scrollable = document.getElementsByClassName('mat-drawer-content')[0];
		const activeSectionTop = document.getElementById(section).getBoundingClientRect().top;
		scrollable.scrollTop = activeSectionTop - 100 + scrollable.scrollTop;
	}
}
