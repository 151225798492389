import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ListItem } from 'src/app/modules/site/models/overview.model';
import { ContentService } from 'src/app/modules/site/services/content.service';

@Component({
	selector: 'ssn-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
	@Input() public items: ListItem[];
	constructor(private contentService: ContentService) {}

	ngOnInit(): void {}

	public getSafeHtml(text: string): SafeHtml {
		return this.contentService.sanitizeHTML(text);
	}
}
