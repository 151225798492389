import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { SetActiveProject } from 'src/app/app.actions';
import { AppState } from 'src/app/app.state';
import { Project } from '../../models/project.model';
import { ContentService } from '../../services/content.service';
import { ProjectService } from '../../services/project.service';

@Component({
	selector: 'app-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
	@Select(AppState.activeProject)
	private activeProject$: Observable<string>;
	public project: Project;

	constructor(
		private store: Store,
		private route: ActivatedRoute,
		private projectService: ProjectService,
		private contentService: ContentService
	) {}

	ngOnInit(): void {
		this.activeProject$.subscribe((projectId) => {
			if (!projectId) return;
			this.loadProject(projectId);
		});
	}

	private loadProject(projectId: string): void {
		this.projectService.getProject(projectId).subscribe((project) => {
			this.project = project;
		});
	}

	public getSafeHtml(text: string): SafeHtml {
		return this.contentService.sanitizeHTML(text);
	}
}
