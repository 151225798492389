<section *ngIf="country" class="ssn-section ssn-section--padded-top">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">{{ country.name }}: Statistics Overview</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-xl-5">
			<mat-icon [svgIcon]="'investment'" class="mat-icon--large"></mat-icon>
			<h3>Current level of investment in WASH</h3>
			<div class="ssn-expenditure" *ngIf="country?.statistics?.expenditure.year;">
				<div class="ssn-expenditure__details">
					<span class="date">Expenditure in {{country.statistics.expenditure.year}}</span>
					<span class="total">{{ country.statistics.expenditure.total }}</span>
				</div>
				<section *ngIf="country?.statistics?.expenditure?.breakdown;" class="">
					<ssn-bar [breakdown]="country.statistics.expenditure.breakdown" [labelPos]="'top'"></ssn-bar>
				</section>

				<section *ngIf="country?.statistics?.contribution?.breakdown;" class="">
					<ssn-bar [breakdown]="country.statistics.contribution.breakdown" [labelPos]="'bottom'"></ssn-bar>
				</section>
			</div>
			<!-- Wessels - testing START -->
			<section *ngIf="country?.statistics?.expenditure?.comingSoon;" class="">
				<div class="coming-soon">No expenditure data available</div>
			</section>
			<!-- Wessels - testing END -->
		</div>
		<div class="col-12 col-xl-7 ssn-questions">
			<mat-icon [svgIcon]="'resillience-questions'" class="mat-icon--large"></mat-icon>
			<h3>Key WASH Resilience Questions:</h3>
			<ssn-list [items]="country.statistics.questions"></ssn-list>
		</div>
	</div>
</section>

<section class="ssn-section">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Key Urban WASH Statistics</h2>
		</div>
	</div>
	<div class="row">
		<div *ngFor="let chart of country.statistics.urbanStatistics" class="col-12 col-md-4">
			<ssn-pie [data]="chart"></ssn-pie>
		</div>
	</div>
</section>

<section *ngIf="country" class="ssn-section">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">WASH System Overview</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-xl-6">
			<div class="row">
				<div *ngFor="let chart of country.statistics.systemOverview.statistics" class="col-12 col-md-6">
					<ssn-pie [data]="chart"></ssn-pie>
				</div>
			</div>
		</div>
		<div class="col-12 col-xl-6">
			<mat-tab-group
				*ngIf="country.statistics.systemOverview.waterSources"
				mat-stretch-tabs
				class="ssn-water-source-tabs"
			>
				<mat-tab *ngFor="let tab of country.statistics.systemOverview.waterSources" [label]="tab.title"
					><p *ngFor="let p of tab.description" [innerHtml]="getSafeHtml(p)"></p
				></mat-tab>
			</mat-tab-group>
		</div>
	</div>
</section>

<section *ngIf="country" class="ssn-section">
	<div class="row">
		<div class="col-12 col-lg-6">
			<h3 class="ssn-section__heading title-case">Current sanitation service delivery issues</h3>
			<ssn-list [items]="country.statistics.systemOverview.serviceDeliveryIssues"></ssn-list>
		</div>
		<div class="col-12 col-lg-6">
			<h3 class="ssn-section__heading title-case">Current water supply issues</h3>
			<ssn-list [items]="country.statistics.systemOverview.supplyIssues"></ssn-list>
		</div>
	</div>
</section>
