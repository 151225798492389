<div *ngIf="project" class="container-fluid">
	<div class="ssn-project__title">
		<h1>{{ project.name }}</h1>
	</div>
	<div class="ssn-project__description">
		<p *ngFor="let desc of project.description" [innerHtml]="getSafeHtml(desc)"></p>
	</div>

	<mat-tab-group
		class="ssn-project-tabs"
		mat-align-tabs="center"
		animationDuration="0ms"
		(selectedTabChange)="navigate($event)"
		[selectedIndex]="selectedIndex"
	>
		<mat-tab label="Project overview"></mat-tab>
		<mat-tab *ngFor="let country of project.countries" [label]="country.name"></mat-tab>
	</mat-tab-group>
</div>
<router-outlet></router-outlet>
<ssn-footer></ssn-footer>
