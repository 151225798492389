import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectService } from '../../site/services/project.service';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	private _currentTheme = '';

	public scrolling$: BehaviorSubject<Event> = new BehaviorSubject(null);

	constructor(@Inject(DOCUMENT) private document: Document, private projectService: ProjectService) {}

	public setTheme(projectId: string): void {
		if (!projectId) {
			return;
		}

		this.projectService.getProject(projectId).subscribe((project) => {
			if (this._currentTheme) {
				this.document.body.classList.remove('theme--' + this._currentTheme);
			}
			this.document.body.classList.add('theme--' + project.theme);
			this._currentTheme = project.theme;
		});
	}
}
