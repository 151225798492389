<div class="ssn-home-template__content bg-col--beige-dark">
	<div class="container-fluid">
		<div class="col-12 ssn-home-template__content__column">
			<h1 class="font-accent">Climate change and Urban Water, Sanitation and Hygiene</h1>
			<p>
				Climate change is expected to have severe consequences across Africa. Cities in Africa are particularly
				vulnerable to the effects of climate change. This interactive tool provides an overview of the status of Urban
				Water Sanitation and Hygiene (WASH) systems in countries and cities where Future Climate for Africa (FCFA) has
				been working, and the climate risks these WASH systems are facing. It is intended to provide an insight into the
				relationship between future climate and effective WASH service delivery in African cities.
			</p>
			<div class="cta-instruction">
				Explore key WASH and climate issues by selecting the FCFA projects on the map
				<mat-icon [svgIcon]="'arrow-right-long'"></mat-icon>
			</div>
		</div>
	</div>
</div>
<div class="ssn-home-template__map">
	<ssn-map></ssn-map>
</div>
<div class="ssn-home-template__banner bg-col--mustard">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 col-xl-6 ssn-home-template__banner__column">
				<mat-icon [svgIcon]="'exclaimation-circle'"></mat-icon>
				<h2>Key messages for climate scientists and experts</h2>
				<p>
					Relevant and useful climate information for cities in many cases is not readily available or not translated
					into decision-making. Oftentimes the headline messages around future climate are what is needed to inform
					resilient infrastructure development, rather than multiple very detailed datasets
				</p>
			</div>
			<div class="col-12 col-xl-6 ssn-home-template__banner__column">
				<mat-icon [svgIcon]="'exclaimation-circle'"></mat-icon>
				<h2>Key messages for WASH practitioners</h2>
				<p>
					Many cities in Africa still have significant backlogs in WASH service delivery, and are already impacted by
					climate variability. Focus on the basics – good active management of existing systems and infrastructure is a
					cost-effective way to enhance resilience, no matter how the climate changes in the future.
				</p>
			</div>
		</div>
	</div>
</div>
<div class="ssn-home-template__content-overflow bg-col--beige-dark"></div>
<div class="ssn-home-template__map-overflow bg-col--pale-blue-light"></div>
