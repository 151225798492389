<div class="container-fluid">
	<div *ngIf="showResourceLink" class="ssn-footer__top row bg-col--accent">
		<div class="col-12">
			<p>
				For more detailed information visit our
				<a class="btn-rounded btn-resources" [routerLink]="['/resources']">Resource page</a>
			</p>
		</div>
	</div>
	<div class="ssn-footer__bottom row">
		<div class="col-12">
			Published by Future Climate for Arica
			<a href="http://www.futureclimateafrica.org" target="_blank">www.futureclimateafrica.org</a>
		</div>
	</div>
</div>
