<section *ngIf="country?.scenarios?.infographics;" class="ssn-section">
	<div class="ssn-infographics">
		<div class="ssn-infographics__slider row">
			<div class="col-12">
				<owl-carousel-o [options]="customOptions" #slider>
					<ng-container *ngFor="let slide of country.scenarios.infographics; let i = index">
						<ng-template carouselSlide [id]="'slide-'+i">
							<div class="ssn-system-map ssn-system-map--slide-{{i+1}}">
								<h2 class="ssn-system-map__heading"><span>{{ slide.heading }}</span></h2>
								<div class="ssn-system-map__container">
									<div class="ssn-system-map__image">
										<img [src]="slide.image.url" [alt]="slide.image.description" />
										<button
											*ngFor="let level of slide.impactLevels; let j = index"
											(click)="setActiveLevel(level.id, i)"
											class="{{level.id}}"
										>
											{{ level.heading }}
										</button>
									</div>
									<div class="ssn-system-map__content">
										<mat-accordion displayMode="flat">
											<mat-expansion-panel
												*ngFor="let level of slide.impactLevels;"
												hideToggle
												[expanded]="level.expanded"
											>
												<mat-expansion-panel-header collapsedHeight="3rem" expandedHeight="3rem">
													<mat-panel-title class="mat-expansion-panel-header-title--{{ level.headingType }}">
														<mat-icon [svgIcon]="'arrow-right-short'"></mat-icon>
														{{ level.heading}}
													</mat-panel-title>
												</mat-expansion-panel-header>
												<ul class="ssn-impact-list">
													<li *ngFor="let impact of level.impacts">
														<span
															class="impact-level impact-level--{{ impact.level }}"
															[aria-label]="getImpactLevelAriaLabel(impact.level)"
														></span>
														{{ impact.description }}
													</li>
												</ul>
											</mat-expansion-panel>
										</mat-accordion>

										<dl class="impact-levels">
											<dt><span class="impact-level impact-level--low" aria-label="Yellow triangle"></span></dt>
											<dd>Low intensity impacts</dd>

											<dt><span class="impact-level impact-level--medium" aria-label="Orange triangle"></span></dt>
											<dd>Medium intensity impacts</dd>

											<dt><span class="impact-level impact-level--high" aria-label="Red triangle"></span></dt>
											<dd>High intensity impacts</dd>
										</dl>
									</div>
								</div>
							</div>
						</ng-template>
					</ng-container>
				</owl-carousel-o>
			</div>
		</div>

		<mat-tab-group
			dynamicHeight
			mat-align-tabs="center"
			class="ssn-infographics__tabs"
			(selectedTabChange)="slider.to('slide-' + $event.index)"
			#scenarioTabs
		>
			<mat-tab *ngFor="let slide of country.scenarios.infographics; let i = index" [label]="slide.title">
				<h2 class="ssn-infographics__title">Recommended actions</h2>
				<div class="ssn-infographics__actions">
					<div *ngFor="let action of slide.actions; let j = index" class="ssn-infographics__actions__action">
						<div class="dot">{{j + 1}}</div>
						<div class="content" [innerHtml]="getSafeHtml(action.item)"></div>
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</section>

<section *ngIf="country?.scenarios?.comingSoon;" class="ssn-section">
	<div class="coming-soon">No data available</div>
</section>
