export class SetActiveProject {
	static readonly type = '[APP] Set Active Project';
	constructor(public payload: string) {}
}
export class SetActiveCountry {
	static readonly type = '[APP] Set Active Country';
	constructor(public payload: string) {}
}
export class SetNavbarState {
	static readonly type = '[APP] Set Navbar State';
	constructor(public payload: boolean) {}
}
