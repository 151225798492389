import { ElementRef } from '@angular/core';
import { AfterViewInit, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetActiveProject } from 'src/app/app.actions';
import { Project } from '../../models/project.model';
import { ContentService } from '../../services/content.service';
import { ProjectService } from '../../services/project.service';

@Component({
	selector: 'app-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
	@HostBinding('attr.class') private layoutCls = 'ssn-project';
	@ViewChild('tabs') private tabs: MatTabGroup;
	@ViewChild('arrow') private tabArrow: HTMLDivElement;
	@ViewChild('viewport') private viewport: ElementRef<HTMLDivElement>;
	@ViewChild('scroller') private scroller: ElementRef<HTMLDivElement>;

	public project: Project;
	public selectedIndex = 0;

	public get arrowPosition(): { [key: string]: string | number } {
		const activeTab: HTMLAnchorElement = document.querySelector('.ssn-project-tabs__tab.active');
		if (activeTab) {
			return {
				opacity: 1,
				left: activeTab.offsetLeft + activeTab.clientWidth * 0.5 + 'px'
			};
		} else {
			return {
				opacity: 0
			};
		}
	}

	public get isScrollable(): boolean {
		if (!this.viewport || !this.scroller) {
			return false;
		}
		const viewportEl = this.viewport.nativeElement;
		const scrollerEl = this.scroller.nativeElement;
		return scrollerEl.clientWidth > viewportEl.clientWidth;
	}

	constructor(
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		private projectService: ProjectService,
		private contentService: ContentService
	) {}

	public ngOnInit(): void {
		this.route.params.subscribe((params) => {
			this.loadProject(params.id, () => {
				this.setActiveTab();
			});
		});
		this.router.events.subscribe((evt) => {
			if (evt instanceof NavigationEnd) {
				this.setActiveTab();
			}
		});
	}

	private setActiveTab(): void {
		if (this.route.children) {
			if ('country' in this.route.children[0].snapshot.params) {
				// country page
				this.selectedIndex =
					this.project.countries
						.map((c) => c.name.toLowerCase().replace(' ', '-'))
						.indexOf(this.route.children[0].snapshot.params.country) + 1;
			} else {
				// overview page
				this.selectedIndex = 0;
			}
		}
	}

	private loadProject(id: string, cb?: () => void): void {
		this.projectService.getProject(id).subscribe((project) => {
			this.project = project;
			this.store.dispatch(new SetActiveProject(project.id));
			cb();
		});
	}

	public getSafeHtml(text: string): SafeHtml {
		return this.contentService.sanitizeHTML(text);
	}

	public scrollLeft(): void {
		const marginLeft = parseInt(this.scroller.nativeElement.style.marginLeft) || 0;
		const scrollAmount = this.viewport.nativeElement.clientWidth;
		if (marginLeft < 0) {
			this.scroller.nativeElement.style.marginLeft = marginLeft + scrollAmount + 'px';
		}
	}
	public scrollRight(): void {
		const marginLeft = parseInt(this.scroller.nativeElement.style.marginLeft) || 0;
		const scrollAmount = this.viewport.nativeElement.clientWidth;
		if (Math.abs(marginLeft) < this.viewport.nativeElement.scrollWidth - this.viewport.nativeElement.clientWidth) {
			this.scroller.nativeElement.style.marginLeft = marginLeft - scrollAmount + 'px';
		}
	}

	public navigate(evt: MatTabChangeEvent): void {
		if (evt.index === 0) {
			this.router.navigate(['/projects', this.project.id, 'overview']);
			return;
		}
		const country = evt.tab.textLabel.toLowerCase().replace(' ', '-');
		this.router.navigate(['/projects', this.project.id, 'country', country]);
	}
}
