<section
	*ngIf="project; else placeholder"
	class="ssn-section ssn-section--padded-top ssn-section--padded-bottom bg-col--beige-dark"
>
	<div class="row">
		<div class="col-12 col-lg-8">
			<h2 class="ssn-section__heading">Climate overview</h2>

			<div class="row">
				<div class="col-12 col-md-6">
					<mat-icon [svgIcon]="'current-climate'" class="mat-icon--large"></mat-icon>
					<h3>Current climate</h3>
					<p *ngFor="let p of project.overview.currentClimate" [innerHtml]="getSafeHtml(p)"></p>
				</div>
				<div class="col-12 col-md-6">
					<mat-icon [svgIcon]="'future-climate'" class="mat-icon--large"></mat-icon>
					<h3>Probable future climate</h3>
					<p *ngFor="let p of project.overview.futureClimate" [innerHtml]="getSafeHtml(p)"></p>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-4">
			<div class="ssn-overview-card">
				<div class="ssn-overview-card__image">
					<img [src]="project.overview.issues.image.url" [alt]="project.overview.issues.image.description" />
				</div>
				<div class="ssn-overview-card__title">Key WASH-Climate Issues</div>
				<div class="ssn-overview-card__content">
					<ul>
						<li *ngFor="let item of project.overview.issues.description" [innerHtml]="getSafeHtml(item)"></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<section *ngIf="project" class="ssn-section ssn-section--padded-top">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Key Urban WASH Statistics</h2>
		</div>
	</div>
	<div class="row">
		<div *ngFor="let chart of project.overview.urbanStatistics" class="col-12 col-lg-4">
			<ssn-pie [data]="chart"></ssn-pie>
		</div>
	</div>
</section>

<section *ngIf="project" class="ssn-section">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">WASH System Overview</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-lg-6">
			<div class="row">
				<div *ngFor="let chart of project.overview.systemOverview.statistics" class="col-12 col-md-6">
					<ssn-pie [data]="chart"></ssn-pie>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-6">
			<h4>Water Supply Sources</h4>
			<mat-tab-group
				*ngIf="project.overview.systemOverview.waterSources"
				mat-stretch-tabs
				class="ssn-water-source-tabs"
			>
				<mat-tab *ngFor="let tab of project.overview.systemOverview.waterSources" [label]="tab.title"
					><p *ngFor="let p of tab.description" [innerHtml]="getSafeHtml(p)"></p
				></mat-tab>
			</mat-tab-group>
		</div>
	</div>
</section>

<section *ngIf="project" class="ssn-section">
	<div class="row">
		<div class="col-12 col-lg-6">
			<h3 class="ssn-section__heading">Current sanitation service delivery issues</h3>
			<ssn-list [items]="project.overview.systemOverview.serviceDeliveryIssues"></ssn-list>
		</div>
		<div class="col-12 col-lg-6">
			<h3 class="ssn-section__heading">Current water supply issues</h3>
			<ssn-list [items]="project.overview.systemOverview.supplyIssues"></ssn-list>
		</div>
	</div>
</section>

<ng-template #placeholder>
	<div class="ssn-page-placeholder"></div>
</ng-template>
