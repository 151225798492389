import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RootComponent } from './modules/layout/components/root/root.component';
import { LayoutModule } from './modules/layout/layout.module';
import { SharedModule } from './modules/shared/shared.module';
import { SiteModule } from './modules/site/site.module';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './app.state';
import { environment } from 'src/environments/environment';

@NgModule({
	declarations: [],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		NgxsModule.forRoot([AppState], {
			developmentMode: !environment.production
		}),
		LayoutModule,
		SharedModule,
		SiteModule
	],
	providers: [],
	bootstrap: [RootComponent]
})
export class AppModule {}
