import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ListComponent } from './components/list/list.component';
import { PieComponent } from './components/pie/pie.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';

import { ChartsModule } from 'ng2-charts';
import { BarComponent } from './components/bar/bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MapComponent } from './components/map/map.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';

const COMPONENTS = [ListComponent, PieComponent, BarComponent, FooterComponent, MapComponent, ScrollSpyDirective];
const MAT_COMPONENTS = [MatSidenavModule, MatIconModule, MatButtonModule, MatTabsModule, MatExpansionModule];

@NgModule({
	declarations: [...COMPONENTS],
	imports: [CommonModule, RouterModule, ChartsModule, ...MAT_COMPONENTS],
	exports: [...MAT_COMPONENTS, ...COMPONENTS]
})
export class SharedModule {}
