import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootComponent } from './components/root/root.component';
import { MainComponent } from './components/main/main.component';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	declarations: [RootComponent, MainComponent, NavigationComponent],
	imports: [CommonModule, RouterModule, SharedModule],
	exports: [MainComponent]
})
export class LayoutModule {}
