<div class="bar-label flex-column">
	<div *ngFor="let segment of breakdown" class="ssn-bar__segment-label" [ngStyle]="getSegmentLabelStyle(segment)">
		<div class="label">
			<span class="value">{{ segment.value }}%</span><span class="description">{{ segment.label }}</span>
		</div>
	</div>
</div>
<div class="bar">
	<div *ngFor="let segment of breakdown" class="ssn-bar__segment" [ngStyle]="getSegmentStyle(segment)"></div>
</div>
