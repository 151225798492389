import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Breakdown } from 'src/app/modules/site/models/country.model';

@Component({
	selector: 'ssn-bar',
	templateUrl: './bar.component.html',
	styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {
	@HostBinding('attr.class') private layoutCls = 'ssn-bar';
	@HostBinding('class.ssn-bar--top') private topPosCls = true;
	@HostBinding('class.ssn-bar--bottom') private bottomPosCls = false;
	private _labelPos: string;

	@Input() public breakdown: Breakdown[];
	@Input() public set labelPos(pos: string) {
		if (!pos) {
			pos = 'top';
		}
		this._labelPos = pos;

		if (this._labelPos === 'top') {
			this.topPosCls = true;
			this.bottomPosCls = false;
		} else {
			this.topPosCls = false;
			this.bottomPosCls = true;
		}
	}
	public get labelPos(): string {
		return this._labelPos;
	}
	constructor() {}

	ngOnInit(): void {}

	public getSegmentStyle(segment: Breakdown) {
		return {
			width: segment.value + '%',
			minWidth: `12%`,
			backgroundColor: segment.color,
			color: segment.color
		};
	}
	public getSegmentLabelStyle(segment: Breakdown) {
		return {
			width: segment.value + '%',
			minWidth: `12%`,
			color: segment.color,
			textAlign: segment.value > 85 ? 'right' : 'left'
		};
	}
}
