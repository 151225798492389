import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
	selector: 'ssn-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	@HostBinding('attr.class') private layoutCls = 'ssn-footer';
	@Input() public showResourceLink = true;

	constructor() {}

	ngOnInit(): void {}
}
