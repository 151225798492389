import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SiteRoutingModule } from './site-routing.module';
import { HomeComponent } from './components/home/home.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ProjectComponent } from './components/project/project.component';
import { ScenariosComponent } from './components/scenarios/scenarios.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { CountryComponent } from './components/country/country.component';
import { SharedModule } from '../shared/shared.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ResourcesComponent } from './components/resources/resources.component';

@NgModule({
	declarations: [
		HomeComponent,
		ProjectComponent,
		OverviewComponent,
		ScenariosComponent,
		StatisticsComponent,
		CountryComponent,
		ResourcesComponent
	],
	imports: [CommonModule, SiteRoutingModule, CarouselModule, SharedModule]
})
export class SiteModule {}
