import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetActiveProject } from 'src/app/app.actions';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	@HostBinding('attr.class') private layoutCls = 'ssn-home-template';
	constructor(private store: Store) {}

	ngOnInit(): void {
		this.store.dispatch(new SetActiveProject(null));
	}
}
