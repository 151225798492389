<div *ngIf="loaded; else placeholder">
	<div class="ssn-page-tabs bg-col--beige-dark">
		<button [ngClass]="{active: activeSection === 'scenarios'}" (click)="scrollToSection('scenarios')">
			WASH Scenarios
		</button>
		<button [ngClass]="{active: activeSection === 'statistics'}" (click)="scrollToSection('statistics')">
			Statistics overview
		</button>
	</div>

	<div class="container-fluid bg-col--beige-dark">
		<div id="scenarios">
			<ssn-scenarios [country]="country"></ssn-scenarios>
		</div>
	</div>
	<div class="container-fluid">
		<div id="statistics">
			<ssn-statistics [country]="country"></ssn-statistics>
		</div>
	</div>
</div>
<ng-template #placeholder>
	<div class="ssn-page-placeholder"></div>
</ng-template>
