<ng-container *ngIf="shouldShow">
	<div class="ssn-pie-chart__title">{{ data.label }}</div>
	<canvas
		*ngIf="pieChartData && pieChartColors"
		baseChart
		[data]="pieChartData"
		[labels]="pieChartLabels"
		[chartType]="pieChartType"
		[options]="pieChartOptions"
		[plugins]="pieChartPlugins"
		[legend]="pieChartLegend"
		[colors]="pieChartColors"
	>
	</canvas>
	<div class="ssn-pie-chart__legend">
		<div *ngFor="let stat of data.stats" class="ssn-pie-chart__legend__item">
			<span class="percentage" [ngStyle]="{ color: stat.color }"> {{ stat.value }}% </span>
			<span class="label"> {{stat.label}} </span>
		</div>
	</div>
</ng-container>
