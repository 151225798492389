import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { Country } from '../../models/country.model';
import { ContentService } from '../../services/content.service';
import { ProjectService } from '../../services/project.service';

@Component({
	selector: 'ssn-statistics',
	templateUrl: './statistics.component.html',
	styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent {
	// @Select(AppState.activeProject)
	// private activeProject$: Observable<string>;
	// @Select(AppState.activeCountry)
	// private activeCountry$: Observable<string>;

	// public country: Country;

	// constructor(private projectService: ProjectService, private contentService: ContentService) {}

	// ngOnInit(): void {
	// 	combineLatest(this.activeProject$, this.activeCountry$).subscribe(([projectId, countryUrl]) => {
	// 		this.loadCountry(projectId, countryUrl);
	// 	});
	// }

	// public getSafeHtml(text: string): SafeHtml {
	// 	return this.contentService.sanitizeHTML(text);
	// }

	// private loadCountry(projectId: string, countryUrl: string): void {
	// 	this.projectService.getProjectCountry(projectId, countryUrl).subscribe((country) => {
	// 		this.country = country;
	// 	});
	// }

	@Input() public country: Country;
	constructor(private contentService: ContentService) {}

	public getSafeHtml(text: string): SafeHtml {
		return this.contentService.sanitizeHTML(text);
	}
}
