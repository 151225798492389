import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { SafeHtml } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { combineLatest, CombineLatestOperator } from 'rxjs/internal/observable/combineLatest';
import { takeWhile } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { Country } from '../../models/country.model';
import { ContentService } from '../../services/content.service';

@Component({
	selector: 'ssn-scenarios',
	templateUrl: './scenarios.component.html',
	styleUrls: ['./scenarios.component.scss']
})
export class ScenariosComponent implements OnInit, OnDestroy {
	@ViewChild(MatAccordion) public accordion: MatAccordion;
	@ViewChild('scenarioTabs') private tabs: MatTabGroup;

	@Input() public country: Country;
	private alive = true;

	public customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		navText: ['', ''],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 1
			},
			740: {
				items: 1
			},
			940: {
				items: 1
			}
		},
		nav: false
	};

	constructor(private contentService: ContentService) {}

	ngOnInit() {
		this.contentService.countryLoaded$.pipe(takeWhile(() => this.alive)).subscribe(() => {
			this.tabs.selectedIndex = 0;
		});
	}

	ngOnDestroy() {
		this.alive = false;
	}

	public getSafeHtml(text: string): SafeHtml {
		return this.contentService.sanitizeHTML(text);
	}

	public setActiveSlide(evt: MatTabChangeEvent): void {
		console.log(evt);
	}

	public setActiveLevel(levelId: string, infographIndex: number) {
		let impactLevels = this.country.scenarios.infographics[infographIndex].impactLevels;
		impactLevels = impactLevels.map((level) => {
			if (level.id === levelId) {
				level.expanded = true;
			} else {
				level.expanded = false;
			}
			return level;
		});
	}

	public getImpactLevelAriaLabel(level: 'low' | 'medium' | 'high'): string {
		switch (level) {
			case 'low':
				return 'Yellow triangle';
			case 'medium':
				return 'Orange triangle';
			case 'high':
				return 'Red triangle';
			default:
				return;
		}
	}
}
