import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../layout/components/main/main.component';
import { CountryComponent } from './components/country/country.component';
import { HomeComponent } from './components/home/home.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ProjectComponent } from './components/project/project.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ScenariosComponent } from './components/scenarios/scenarios.component';
import { StatisticsComponent } from './components/statistics/statistics.component';

const routes: Routes = [
	{
		path: '',
		component: MainComponent,
		children: [
			{ path: '', redirectTo: 'home', pathMatch: 'full' },
			{ path: 'home', component: HomeComponent },
			{
				path: 'projects/:id',
				component: ProjectComponent,
				children: [
					{ path: '', redirectTo: 'overview', pathMatch: 'full' },
					{ path: 'overview', component: OverviewComponent },
					{
						path: 'country/:country',
						component: CountryComponent
					}
				]
			},
			{ path: 'resources', component: ResourcesComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SiteRoutingModule {}
