<div class="container-fluid">
	<div class="ssn-project__title">
		<h1>RESOURCES</h1>
	</div>
	<div class="ssn-project__description">
		<p>
			For more information on FCFA visit: <a href="https://futureclimateafrica.org">https://futureclimateafrica.org/</a>
			or contact
			<a href="info@futureclimateafrica.org"> info@futureclimateafrica.org</a>
		</p>
	</div>
</div>

<section class="ssn-section bg-col--beige-dark">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Videos: An overview of FCFA work</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-lg-6">
			<iframe
				width="560"
				height="315"
				src="https://www.youtube.com/embed/AGOMCf8fSCA"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
		</div>
		<div class="col-12 col-lg-6">
			<iframe
				width="560"
				height="315"
				src="https://www.youtube.com/embed/xgxaySy3-QE"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
		</div>
	</div>
</section>

<section class="ssn-section bg-col--beige-dark">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Featured FCFA news stories</h2>
		</div>
	</div>
	<div class="row">
		<div *ngFor="let card of news" class="col-12 col-md-6 col-lg-3">
			<div class="ssn-card">
				<div class="ssn-card__image">
					<a href="{{card.target}}" target="_blank"> <img [src]="card.image.url" [alt]="card.image.description" /></a>
				</div>
				<div class="ssn-card__title">
					<a href="{{card.target}}" target="_blank">{{ card.title }}</a>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="ssn-section bg-col--beige-dark">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Selection of key FCFA resources</h2>
		</div>
	</div>
	<div class="row">
		<div *ngFor="let card of publications" class="col-12 col-md-6 col-lg-3">
			<div class="ssn-card">
				<div class="ssn-card__image">
					<a href="{{card.target}}" target="_blank"><img [src]="card.image.url" [alt]="card.image.description" /></a>
				</div>
				<div class="ssn-card__title"><a href="{{card.target}}" target="_blank">{{ card.title }}</a></div>
			</div>
		</div>
	</div>
</section>

<section class="ssn-section bg-col--beige-dark">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Key WASH tools and resources</h2>
		</div>
	</div>
	<div class="row">
		<div *ngFor="let card of tools" class="col-12 col-md-6 col-lg-3">
			<div class="ssn-card">
				<div class="ssn-card__image">
					<a href="{{card.target}}" target="_blank"><img [src]="card.image.url" [alt]="card.image.description" /></a>
				</div>
				<div class="ssn-card__title"><a href="{{card.target}}" target="_blank">{{ card.title }}</a></div>
			</div>
		</div>
	</div>
</section>

<section class="ssn-section bg-col--beige-dark">
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Acknowledgements and Site credits</h2>
		</div>
	</div>
	<div class="row">
		<div class="col-12 ssn-sponsor-logos">
			<img src="./assets/images/ukaid.svg" alt="UKaid" />
			<img src="./assets/images/ukri.svg" alt="UKRI" />
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Contributers</h2>

			<p>We would like to thank the following contributors (listed alphabetically):</p>
			<ul>
				<li>Roy Bouwer, SouthSouthNorth (Coordination, Capacity Development and Knowledge Exchange Unit)</li>
				<li>James Cullis, Zutari (FRACTAL)</li>
				<li>Anya Eiliers, Zutari (FRACTAL)</li>
				<li>Barbara Evans, University of Leeds (HyCRISTAL)</li>
				<li>Rebecca Ilunga, Zutari (FRACTAL)</li>
				<li>John Marsham, University of Leeds (HyCRISTAL)</li>
				<li>Dave Rowell, Met Office (HyCRISTAL & AMMA-2050)</li>
				<li>Celia Way, University of Leeds (HyCRISTAL)</li>
			</ul>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<h2 class="ssn-section__heading">Disclaimer</h2>

			<p>
				This document is an output from a project funded by the UK Foreign, Commonwealth and Development Office (FCDO)
				and the Natural Environment Research Council (NERC) for the benefit of developing countries and the advance of
				scientific research. However, the views expressed and information contained in it are not necessarily those of,
				or endorsed by FCDO or NERC, which can accept no responsibility for such views or information or for any
				reliance placed on them. This publication has been prepared for general guidance on matters of interest only,
				and does not constitute professional advice. You should not act upon the information contained in this
				publication without obtaining specific professional advice. No representation or warranty (express or implied)
				is given as to the accuracy or completeness of the information contained in this publication, and, to the extent
				permitted by law, the Future Climate for Africa’s members, FCDO, NERC, their advisors and the authors and
				distributors of this publication do not accept or assume any liability, responsibility or duty of care for any
				consequences of you or anyone else acting, or refraining to act, in reliance on the information contained in
				this publication or for any decision based on it.
			</p>
		</div>
	</div>
</section>

<ssn-footer [showResourceLink]="false"></ssn-footer>
