import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { LayoutService } from '../../services/layout.service';

@Component({
	selector: 'ssn-root',
	templateUrl: './root.component.html',
	styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
	@Select(AppState.activeProject)
	public activeProject$: Observable<string>;

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private layoutService: LayoutService
	) {}

	ngOnInit(): void {
		this.addCustomIcons();
		this.activeProject$.subscribe((theme) => {
			this.layoutService.setTheme(theme);
		});
	}

	private addCustomIcons(): void {
		this.matIconRegistry.addSvgIcon('menu', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/menu.svg'));

		this.matIconRegistry.addSvgIcon(
			'current-climate',
			this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/current-climate.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'future-climate',
			this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/future-climate.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'investment',
			this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/investment.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'resillience-questions',
			this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/resillience-questions.svg')
		);

		this.matIconRegistry.addSvgIcon(
			'arrow-right-short',
			this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/arrow-right-short.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'arrow-right-long',
			this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/arrow-right-long.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'question-circle-solid',
			this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/question-circle-solid.svg')
		);
		this.matIconRegistry.addSvgIcon(
			'exclaimation-circle',
			this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/exclaimation-circle.svg')
		);
		this.matIconRegistry.addSvgIcon('drop', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/drop.svg'));
	}
}
