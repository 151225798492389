import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Project } from '../models/project.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from '../models/country.model';

@Injectable({
	providedIn: 'root'
})
export class ProjectService {
	private localUrl = 'assets/data/projects.json';
	private projects: Observable<Project[]>;
	constructor(private http: HttpClient) {}

	public getProjects(): Observable<Project[]> {
		this.projects = this.http.get<Project[]>(this.localUrl);
		return this.projects;
	}

	public getProject(id: string): Observable<Project> {
		return this.projects.pipe(map((projects) => projects.find((p) => p.id === id)));
	}

	public getProjectCountry(projectId: string, countryUrl: string): Observable<Country> {
		return this.projects.pipe(
			map((projects) => {
				return projects.find((p) => p.id === projectId).countries.find((c) => c.url === countryUrl);
			})
		);
	}
}
