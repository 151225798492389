import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
	selector: 'app-resources',
	templateUrl: './resources.component.html',
	styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
	@HostBinding('attr.class') private layoutCls = 'ssn-resources';
	public news: any[] = [
		{
			image: {
				url: './assets/images/resources/featured-stories/1.jpg',
				description: 'Learning From Hycristal’s Urban WASH Research In East Africa'
			},
			title: 'Learning From Hycristal’s Urban WASH Research In East Africa',
			target: 'https://futureclimateafrica.org/news/learning-from-hycristals-urban-wash-research-in-east-africa/'
		},
		{
			image: {
				url: './assets/images/resources/featured-stories/2.jpg',
				description: 'Urban Flood Stories Paint Fuller Picture Of Climate Risk In East African Cities'
			},
			title: 'Urban Flood Stories Paint Fuller Picture Of Climate Risk In East African Cities',
			target:
				'https://futureclimateafrica.org/news/urban-flood-stories-paint-fuller-picture-of-climate-risk-in-east-african-cities/'
		},
		{
			image: {
				url: './assets/images/resources/featured-stories/3.jpg',
				description: 'Coping With Urban Flooding In Ouagadougou, Burkina Faso'
			},
			title: 'Coping With Urban Flooding In Ouagadougou, Burkina Faso',
			target: 'https://futureclimateafrica.org/news/coping-with-urban-flooding-in-ouagadougou-burkina-faso/'
		},
		{
			image: {
				url: './assets/images/resources/featured-stories/4.jpg',
				description: 'Harare Water Delivery: Researcher-practitioner Collaboration Supports Climate Agility'
			},
			title: 'Harare Water Delivery: Researcher-practitioner Collaboration Supports Climate Agility',
			target:
				'https://futureclimateafrica.org/news/harare-water-delivery-researcher-practitioner-collaboration-supports-climate-agility/'
		},
		{
			image: {
				url: './assets/images/resources/featured-stories/5.jpg',
				description: 'Novel Approach Can Help Cities Map Their ‘water Risk’'
			},
			title: 'Novel Approach Can Help Cities Map Their ‘water Risk’',
			target: 'https://futureclimateafrica.org/news/novel-approach-can-help-cities-map-their-water-risk/'
		},
		{
			image: {
				url: './assets/images/resources/featured-stories/6.jpg',
				description: 'Lusaka’s water security needs integrated planning and governance'
			},
			title: 'Lusaka’s water security needs integrated planning and governance',
			target: 'https://futureclimateafrica.org/news/lusakas-water-security-needs-integrated-planning-and-governance/'
		}
	];
	public publications: any[] = [
		{
			image: {
				url: './assets/images/resources/key-fcfa-resources/1.jpg',
				description: 'Brief: Possible futures for urban East Africa under a changing climate'
			},
			title: 'Brief: Possible futures for urban East Africa under a changing climate',
			target:
				'https://futureclimateafrica.org/resource/possible-futures-for-urban-east-africa-under-a-changing-climate-2/'
		},
		{
			image: {
				url: './assets/images/resources/key-fcfa-resources/2.jpg',
				description: 'Infographic: Possible futures for urban East Africa under a changing climate'
			},
			title: 'Infographic: Possible futures for urban East Africa under a changing climate',
			target:
				'https://futureclimateafrica.org/resource/possible-futures-for-urban-east-africa-under-a-changing-climate/'
		},
		{
			image: {
				url: './assets/images/resources/key-fcfa-resources/3.jpg',
				description:
					'Climate risk narratives: An iterative reflective process for co-producing and integrating climate knowledge'
			},
			title:
				'Climate risk narratives: An iterative reflective process for co-producing and integrating climate knowledge',
			target:
				'https://futureclimateafrica.org/resource/climate-risk-narratives-an-iterative-reflective-process-for-co-producing-and-integrating-climate-knowledge/'
		},
		{
			image: {
				url: './assets/images/resources/key-fcfa-resources/4.jpg',
				description: 'A manual for co‑production in African weather and climate services'
			},
			title: 'A manual for co‑production in African weather and climate services',
			target: 'https://futureclimateafrica.org/coproduction-manual/'
		},
		{
			image: {
				url: './assets/images/resources/key-fcfa-resources/5.jpg',
				description:
					'The future-climate, current-policy framework: towards an approach linking climate science to sector policy development'
			},
			title:
				'The future-climate, current-policy framework: towards an approach linking climate science to sector policy development',
			target:
				'https://futureclimateafrica.org/resource/the-future-climate-current-policy-framework-towards-an-approach-linking-climate-science-to-sector-policy-development/'
		}
	];
	public tools: any[] = [
		{
			image: {
				url: './assets/images/resources/wash-tools/1.jpg',
				description: 'The SFD Approach'
			},
			title: 'The SFD Approach',
			target: 'https://sfd.susana.org/'
		},
		{
			image: {
				url: './assets/images/resources/wash-tools/2.jpg',
				description: 'WHO/UNICEF Joint Monitoring Programme for Water Supply, Sanitation and Hygiene (JMP)'
			},
			title: 'WHO/UNICEF Joint Monitoring Programme for Water Supply, Sanitation and Hygiene (JMP)',
			target: 'https://washdata.org/'
		},
		{
			image: {
				url: './assets/images/resources/wash-tools/3.jpg',
				description: 'Compendium of Sanitation Systems and Technologies'
			},
			title: 'Compendium of Sanitation Systems and Technologies',
			target: 'https://www.susana.org/en/knowledge-hub/resources-and-publications/library/details/454'
		},
		{
			image: {
				url: './assets/images/resources/wash-tools/4.jpg',
				description: 'Un-water Global Analysis And Assessment Of Sanitation And Drinking-water (Glaas)'
			},
			title: 'Un-water Global Analysis And Assessment Of Sanitation And Drinking-water (Glaas)',
			target:
				'https://www.who.int/water_sanitation_health/monitoring/investments/glaas-2018-2019-country-survey-documents/en/'
		}
	];
	constructor() {}

	ngOnInit(): void {}
}
