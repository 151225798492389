import { AfterViewInit, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ChartColor, ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PieChartData } from 'src/app/modules/site/models/overview.model';

@Component({
	selector: 'ssn-pie',
	templateUrl: './pie.component.html',
	styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {
	@HostBinding('attr.class') private layoutCls = 'ssn-pie-chart';
	@Input() public data: PieChartData;

	public pieChartOptions: ChartOptions = {
		responsive: true
	};
	public pieChartLabels: Label[];
	public pieChartData: SingleDataSet;
	public pieChartColors: any;
	public pieChartType: ChartType = 'pie';
	public pieChartLegend = false;
	public pieChartPlugins = [];

	private _shouldShow$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public shouldShow: boolean;

	constructor() {}

	public ngOnInit(): void {
		this.generateChartData();
		this._shouldShow$.pipe(debounceTime(2000)).subscribe((v) => (this.shouldShow = v));
	}

	private generateChartData(): void {
		this.pieChartOptions = {
			responsive: true,
			title: { display: false },
			tooltips: {
				enabled: true
			},
			elements: {
				arc: {
					borderWidth: 0
				}
			}
		};
		this.pieChartLabels = this.data.stats.map((d) => d.label);
		this.pieChartColors = [{ backgroundColor: this.data.stats.map((d) => d.color) }];
		this.pieChartData = this.data.stats.map((d) => d.value);
	}
}
