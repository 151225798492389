import { EventEmitter, Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
export class ContentService {
	public countryLoaded$: EventEmitter<null> = new EventEmitter<null>();

	constructor(private sanitize: DomSanitizer) {}

	public sanitizeHTML(text: string): SafeHtml {
		return this.sanitize.bypassSecurityTrustHtml(text);
	}

	public countryLoaded() {
		this.countryLoaded$.emit();
	}
}
