<a [routerLink]="['/home']" class="logo-container">
	<img class="logo" src="./assets/images/fcfa.svg" alt="Future Climate For Africa logo" />
</a>
<ng-container *ngIf="!(navbarOpen$ | async); else open">
	<button mat-icon-button (click)="openNavbar()" class="ssn-navigation__toggle">
		<mat-icon [svgIcon]="'menu'"></mat-icon>
	</button>
	<div class="fill"></div>
</ng-container>
<ng-template #open>
	<ul class="ssn-menu">
		<li *ngFor="let project of projects" class="ssn-menu__item">
			<a [routerLink]="['/projects', project.id]" [routerLinkActive]="'active'">{{ project.name }}</a>
			<ul *ngIf="project.countries && (activeProject$ | async) === project.id" class="ssn-menu__sub">
				<li class="ssn-menu__sub__item">
					<a [routerLink]="['/projects', project.id, 'overview']" [routerLinkActive]="'active'">
						<mat-icon>east</mat-icon>
						Project Overview
					</a>
				</li>
				<li *ngFor="let country of project.countries" class="ssn-menu__sub__item">
					<a [routerLink]="['/projects', project.id, 'country', country.url]" [routerLinkActive]="'active'">
						<mat-icon>east</mat-icon>
						{{ country.name }}
					</a>
				</li>
			</ul>
		</li>
		<li class="ssn-menu__item">
			<a [routerLink]="['/resources']" [routerLinkActive]="'active'">Resources</a>
		</li>
	</ul>
</ng-template>
<div class="sponsors">
	<p>Funded by</p>
	<img src="./assets/images/ukaid.svg" alt="UK Aid" />
	<img src="./assets/images/ukri.svg" alt="UK RI" />
</div>
